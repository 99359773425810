jQuery(function ($) {
    
    $(document).ready(function(){
        
        // Copy to the clip board the link
        $('.js-referal--link').click( function() {
            $('.js-referal__copy').select();
            document.execCommand('copy');

            if ( document.execCommand('copy') ) {
                $('.js-referal--btn').empty();
                $('.js-referal--btn').html('Copié <i class="fa fa-check"></i>');
            }

            return false;
        });
        
        
        $(".js-mobile-url").on("click", doChooseUrlMobile);
        
        // Choose Url for mobile or desktop version
        function doChooseUrlMobile() {
            var el              = $(this);
            var site            = $(this).data('site');
            var link            = $(this).data('link');
            var message         = $(this).data('message');
            var lag             = 2150;
        
            if(isMobileDevice()){

                // Try to open the good app
                if(site == "twitter"){
                    var deeplink_url = "twitter://post?message=" + encodeURIComponent(message) + encodeURIComponent(link);
                    window.location.href = deeplink_url;
                } else if (site == "messenger"){
                    var deeplink_url = "fb-messenger://share/?link=" + encodeURIComponent(link);
                    window.location.href = deeplink_url;
                } else if (site == "whatsapp") {
                    var deeplink_url = "whatsapp://send?text=" + encodeURIComponent(message) + encodeURIComponent(link);
                    window.location.href = deeplink_url;
                }
                
                setTimeout(function() {

                    // If document not hidden after 2 sec we know they doesn t have the app
                    if (!document.hidden) {
                        urlReferal(site,link);
                    }
                    
                }, lag);

            } else {
                urlReferal(site,link,message);
            }
            return false;
        }

        // Choose the Url for desktop share
        function urlReferal(theSocial, theLink, theMessage){
            if(theSocial == "twitter"){
                var new_url = "https://twitter.com/intent/tweet?text=" + encodeURIComponent(theMessage) + encodeURIComponent(theLink);
                window.open(new_url, '_blank');
            } else if (theSocial == "messenger"){
                FB.ui({
                    method: 'send',
                    link: theLink,
                });
            } else if (theSocial == "whatsapp"){

            }
        }
        
        // Mobile detector
        function isMobileDevice() {
            return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1 || (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) );
        };
    
    });

});
    