jQuery(function ($) {

    $(document).ready(function(){

        if(environment != "dev"){
            $("#ga-event-box-renvoie").click(function(){
                if (typeof ga  !== 'undefined'){ga('send', 'event', 'Commande', 'Button', 'Box renvoyée entierement');}
            });

            $('.ga-event-question').click( function() {
                var page 				= $(this).attr('href');
                var scrollToPixel 		= $(page).offset().top;
                $('html, body').animate( { scrollTop: scrollToPixel}, 500);
                ga('send', 'event', 'FAQ', 'Click', $(this).html(), $(this).data('number'));
                return false;
            });

            $(".ga-event-referal").click(function(){
                if (typeof ga  !== 'undefined'){
                    ga('send', 'event', 'Parrainage', 'Button', $(this).data('social'), $(this).data('number'));
                }
            });
        }

    });

});
