jQuery(function ($) {

    $(document).ready(function(){

        $('.js-btn_menu').click( function() {
            if($(".js-navigation-mobile").hasClass('open')){
                $(".js-navigation-mobile").removeClass('open');
            }
            else{
                $(".js-navigation-mobile").addClass('open');
            }
            return false;
        });

        $(document).on('click', function(event){
            if($('.js-navigation-mobile').hasClass('open') && !$(event.target).closest('.c-navigation-mobile').length){
                $(".js-navigation-mobile").removeClass('open');
            }
        })

        $('#copier-link').click( function() {
            if($("#copier-link").hasClass('open')){
                $('#copier-link').removeClass('open');
            }
            else{
                $('#copier-link').addClass('open');
            }
            return false;
        });
        

        $("#cookie-popup").click(function(){
            $(this).addClass('hide');
            setCookie("cookie1","true", 365);
        });


        // COOKIES

        function getCookie(cname) {
            var name = cname + "=";
            var ca = document.cookie.split(';');
            for(var i=0; i<ca.length; i++) {
                var c = ca[i];
                while (c.charAt(0)==' ') c = c.substring(1);
                if (c.indexOf(name) == 0) return c.substring(name.length,c.length);
            }
            return "";
        };
        function setCookie(cname, cvalue, exdays) {
            var d = new Date();
            d.setTime(d.getTime() + (exdays*24*60*60*1000));
            var expires = "expires="+d.toUTCString();
            document.cookie = cname + "=" + cvalue + "; " + expires + ";secure";
        };
        function checkCookie() {
            var cookie1=getCookie("cookie1");
            if (cookie1!="") {
                $("#cookie-popup").addClass('hide');
            } 
            else{
                $("#cookie-popup").removeClass('hide');
            }
        };
        checkCookie();


        $('.link.copier').click( function() { 
            if( $(this).hasClass("open") ){
                $(this).removeClass("open");
            }
            else{
                $(this).addClass("open");
                $("#copie-link").select();
            }
            
        }).find(".text-hover").click(function(e) {

            return false;
        });

        $("#send-message-styliste").click( function(e) { 
            var href = window.location.href;
            e.preventDefault();
            if ($("#commentaire").length > 0) {
                showLoader.show($('.js-add-msg-styliste'),1);
                var message = $("#commentaire").val();
                message = message.replace(/['"]+/g, '');
                $.ajax({
                    type: "post",
                    url: Routing.generate('cf_core_save_message_styliste'),
                    data: {message: message},
                    success: function(data){
                        window.location.href = href;
                    }
                });
            }
        });

    });

});