var showLoader = {
    
    /*
        Function : showLoader
        Description : Show or Hide a loader on the button 
        Params elBtn : class of the btn
        Params show : 1 = show the loader, 0 = hide the loader
        Params iconOriginal : fontawesome class of the original icon
        Params iconLoader : fontawesome class of loader icon
    */

    show: function(elBtn,show,iconOriginal,iconLoader) {
    
        iconOriginal    = iconOriginal || '';
        iconLoader      = iconLoader || 'fa-spinner fa-pulse';

        if(iconOriginal == ""){
            elBtn.prepend('<i class="c-btn__icon c-btn__icon--left fa"></i>')
        }

        if(show == 1) {
            elBtn.find('.fa').removeClass(iconOriginal);
            elBtn.find('.fa').addClass(iconLoader);
        } else {
            elBtn.find('.fa').removeClass(iconLoader);
            elBtn.find('.fa').addClass(iconOriginal);

            if(iconOriginal == ""){
                elBtn.find('.fa').remove();
            }
        }

    }

}