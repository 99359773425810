var FormChecker = {
    
    regex: function(object) {

        // Required
        var el                  = object.el;
        var regex               = object.regex; 
        var msg                 = object.msg;

        var value               = el.val();
        var parent              = el.closest('.c-form__line');
        var label              = parent.find('.c-form__label_autohidden');

        // Optionnal 
        if(object.nbError){
            var nbError          = object.nbError;
        } else {
            var nbError          = 0;
        }

        if(object.excludeChar){
            var excludeChar     = object.excludeChar;
            for (var i = 0; i < excludeChar.length; i++) {
                if(excludeChar[i] == ' '){
                    value = value.replace(/ /g,'');
                } else {
                    value = value.replace(excludeChar[i],'');
                }
            }
        }
        
        var res = false;
        for (var i = 0; i < regex.length; i++) {
            var regexResultat = new RegExp(regex[i]).test(value);
            if (regexResultat == true){
                res = true;
            }
        }

        if (res == false) {
            if (nbError == 0) {
                parent.addClass('c-form__line--error');
                label.addClass('c-form__label_autohidden_error');
            } else {
                parent.addClass('c-form__line--error--' + nbError);
                label.addClass('c-form__label_autohidden_error');
            }
            return false;
            
        } else {
            if (nbError == 0) {
                parent.removeClass('c-form__line--error');
                label.removeClass('c-form__label_autohidden_error');
            } else {
                parent.removeClass('c-form__line--error--' + nbError);
                label.removeClass('c-form__label_autohidden_error');
            }
            return true;
        }
    }
}
    